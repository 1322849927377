<template>
  <div>
    <Table :table="table"
      @fetch-data="fetchData" @delete="deleteUser" @search-data="initialize" @editItem="editItem"
      @addNew="addNew" /> 

    <v-dialog v-model="dialog" max-width="80%" ref="createNew">
      <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Mở Form</v-btn>
      </template> -->
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ !edit ? 'Thêm mới' : 'Chỉnh sửa' }}</span>
        </v-card-title>
        <v-card-text>
          <div>
            <v-container>
              <v-row>
                <!-- <v-col cols="12" sm="6">
        <input type="file" ref="fileInput" style="display: none;" @change="handleFileUpload" />
        <v-btn @click="openFileInput" outlined color="primary">
          <v-icon left>mdi-cloud-upload</v-icon>
          Chọn ảnh bìa
        </v-btn>
      </v-col> -->
                <!-- <img :src="imageUrl" v-if="imageUrl" alt="Uploaded Image" width="250px" /> -->
                <v-col cols="12" sm="6"> 
                  <v-select label="Loại gói dầu tư" v-model="formData.type_product"
                    :items="product"></v-select>
                </v-col>
                

                <!-- <v-col cols="12" sm="6">
                  <v-text-field outlined v-model="formData.name" label="Tên Gói"
                    :rules="[v => !!v || 'Vui lòng nhập tên gói']"></v-text-field>
                </v-col> -->
                <v-col cols="12" sm="3" >
                  <v-lable>Thời gian đầu tư</v-lable>
                  <v-radio-group v-model="formData.investment_time" v-if="formData.type_product != 3">
                    <v-radio label="3 ngày / 8% lãi" value='3'></v-radio>
                    <v-radio label="5 ngày / 13% lãi" value='5'></v-radio>
                    <v-radio label="7 ngày / 18% lãi" value='7'></v-radio>
                    <v-radio label="12 ngày / 25% lãi" value='12'></v-radio>
                  </v-radio-group>
                  <v-radio-group v-model="formData.investment_time" v-else>
                    <v-radio label="1 tháng / 30% lãi" value='1'></v-radio>
                    <v-radio label="2 tháng / 40% lãi" value='2'></v-radio>
                    <v-radio label="3 tháng / 50% lãi" value='3'></v-radio>
                    <!-- <v-radio label="12 tháng / 25% lãi" value='12'></v-radio> -->
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field outlined disabled :value="calculateDate(formData.investment_time)" label="ngày nhận lãi:" type="text"
                    ></v-text-field>
                </v-col>
               
                <!-- <v-col cols="12" sm="6">
                  <v-text-field outlined v-model="formData.investment_time" label="Thời gian đầu tư" type="number"
                    :rules="[v => !!v || 'Vui lòng nhập thời gian đầu tư']"></v-text-field>
                </v-col> -->
                <v-col cols="12" sm="3">
                  <v-text-field outlined v-model="formData.amount" label="Số tiền đầu tư" type="number"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field outlined v-model="formData.service_charge" label="Phí dịch vụ" type="number"
                    ></v-text-field>
                  </v-col>
                
                  <v-col cols="12" sm="3">
                    <v-autocomplete 
                    v-model="formData.user" 
                    :search-input.sync="search"
                    label="Người đầu tư" 
                    :items=user
                    ></v-autocomplete>
                    
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-select label="Trạng thái" v-model="formData.status"
                    :items="status"></v-select>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <v-label>Thông tin người đầu tư trước</v-label>
                </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field outlined v-model="formData.name_bank" label="Tên ngân hàng" type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field outlined v-model="formData.number_bank"  label="số tài khoản ngân hàng" type="text"
                    ></v-text-field>
                    </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field outlined  v-model="formData.user_name_bank" label="Tên chủ thẻ" type="text"
                    ></v-text-field>
                  </v-col>

                  <!-- <v-col cols="12" sm="4">
                    <v-text-field outlined v-model="formData.usdt_account" label="Tài khoản USDT" type="text"
                    ></v-text-field>
                  </v-col> -->



                  <v-col cols="12" sm="12">
                    <v-label>Thông tin ngân hàng admin</v-label>
                </v-col>

                  <v-col cols="12" sm="4" >
                    <v-text-field disabled outlined v-model="bankAdmin.name_bank" label="Tên ngân hàng" type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field disabled outlined v-model="bankAdmin.number_bank" label="số tài khoản ngân hàng" type="text"
                    ></v-text-field>
                    </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field disabled outlined v-model="bankAdmin.user_name_bank" label="Tên chủ thẻ" type="text"
                    ></v-text-field>
                  </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeForm">Hủy</v-btn>
          <v-btn v-if="!editing" color="blue darken-1" text @click="submitForm">Lưu</v-btn>
          <v-btn v-else color="blue darken-1" text @click="Update">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Table from "@/components/core/TableProduct"
import network from '@/core/network'
import axios from "axios";
// import EditUser from "./EditUser.vue";
import { debounce } from 'lodash';


export default {
  
  components: {
    Table
  },
  data() {
    return {
      
      editing: false,
      edit: false,
      file: null,
      imageUrl: null,
      user: null,
      search: '',
      debouncedSearch: null,
      product: [
        { text: 'Cat Red', value: 0 },
        { text: 'Cat Silver', value: 1 },
        { text: 'Cat Black', value: 2 },
        { text: 'Cat Gold', value: 3 }
      ],
      status : [
        { text: 'Chờ xử lý', value: 0},
        { text: 'Đã mua', value:1},
        { text: 'Dự trữ', value:2},
        { text: 'Kháng cáo', value:3},
      ],
      table: {
        page: 1,
        itemsPerPage: 10,
        totalItems: 0,
        desserts: [],
        search: '',
        headers: [

          // {
          //   text: 'Tên',
          //   value: 'name',
          //   sortable: false
          // },
          {
            text: 'Gói đầu tư',
            value: 'type_product',
            sortable: false
          },
          {
            text: 'Người đầu tư',
            value: 'userId.name',
            sortable: false
          },
          {
            text: 'Đầu tư ',
            value: 'amount', 
            sortable: false
          },
          {
            text: 'Thời gian đầu tư',
            value: 'investment_time',
            sortable: false
          },
          {
            text: 'Phí dịch vụ',
            value: 'service_charge',
            sortable: false
          },
          {
            text: 'Trạng thái',
            value: 'status',
            sortable: false
          },
          {
            text: 'Tạo lúc',
            value: 'created_at',
            sortable: false
          },
          {
            text: 'Ngày nhận lãi',
            value: 'end_at',
            sortable: false
          },

          { text: 'Thao tác', value: 'actions', sortable: false, width: "100px" },
        ],
      },
      dialog: false,
      idProduct: '',
      bankAdmin: {
        name_bank: '',
        user_name_bank: '',
        number_bank: '',
      },
      formData: {
        type_product: 0,
        amount: '',
        investment_time: '3',
        service_charge: 15,
        status: 0,
        image: '',
        user: 0,
        name_bank: '',
        user_name_bank: '',
        number_bank: '',
        usdt_account: '',
      },
    }
  },
  watch: {
    file(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.imageUrl = URL.createObjectURL(newValue);
      }
    },
    search (val) {
       this.fetchListUser(val)
    },
  },
  created() {
    // Khởi tạo hàm debouncedSearch để sử dụng debounce
    this.debouncedSearch = debounce(this.searchUser, 500); // 500ms là khoảng thời gian debounce
  },
  mounted() {
    this.initialize();
    this.searchUser();
    this.getBankConfig();
  },
  methods: {
    getBankConfig() {
            network.get('/configs/get-bank-config').then(res => {
              if (res.data[0]._id) {
                this.bankAdmin = res.data[0];
              }
            })
        },

    calculateDate(daycount) {
      //daycount tính theop tháng
      if (this.formData.type_product == 3) {
        const currentDate = new Date();
        const threeMonthsLater = new Date(currentDate.getFullYear(), currentDate.getMonth() + parseInt(daycount), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds());
        const day = String(threeMonthsLater.getDate()).padStart(2, '0');
        const month = String(threeMonthsLater.getMonth() + 1).padStart(2, '0');
        const year = threeMonthsLater.getFullYear();
        const hours = String(threeMonthsLater.getHours()).padStart(2, '0');
        const minutes = String(threeMonthsLater.getMinutes()).padStart(2, '0');
        const seconds = String(threeMonthsLater.getSeconds()).padStart(2, '0');
        
        return `${day}/${month}/${year} `;
      } else {
      //daycount tính theo ngày
        const currentDate = new Date();
        const threeDaysLater = new Date(currentDate.getTime() + daycount * 24 * 60 * 60 * 1000);
        const day = String(threeDaysLater.getDate()).padStart(2, '0');
        const month = String(threeDaysLater.getMonth() + 1).padStart(2, '0');
        const year = threeDaysLater.getFullYear();
        const hours = String(threeDaysLater.getHours()).padStart(2, '0');
        const minutes = String(threeDaysLater.getMinutes()).padStart(2, '0');
        const seconds = String(threeDaysLater.getSeconds()).padStart(2, '0');
        
        return `${day}/${month}/${year} `;
      }
    },

    fetchData(page) {
      this.page = page;
      this.initialize()
    },
    fetchListUser(val) {
      this.search = val;
      this.debouncedSearch();
    },
    searchUser() {
      network.get('/users',{
        name: this.search,
        page: 0,
        limit: 10
      }).then(res => {
        this.user = res.data.docs.map(item => ({ text: item.name, value: item._id }));
      })
    },
    initialize(searchData) {
      this.search = searchData
      network.get('/products/all-product',
      {
        name: searchData ?? '',
        page: this.table.page,
        limit: 10
      }).then(res => {
        this.table.desserts = res.data.data.docs
        this.table.totalItems = res.data.data.totalDocs
      })
    },

    addNew() {
      this.dialog = true;
    },
    closeForm() {
      this.resetForm();
    },
    editItem(id) {
      this.idProduct = id;
      this.editing = true;
      this.dialog = true;

      network.get(`/products/${id}`).then(res => {
        this.formData = res.data.data;
        if (this.formData?.investment_time) {
          this.formData.investment_time = this.formData?.investment_time.toString();
        }
        if (res.data.data.userId._id) {
          this.getUser(res.data.data.userId._id)
        }
      }).catch(err => {
        console.log(err);
      })
    },
    resetForm() {
      this.editing = false;
      this.dialog = false;
      this.formData = {
        type_product: 0,
        name: '',
        amount: '',
        investment_time: '3',
        // income: 1,
        service_charge: 15,
        status: 0,
        image: '',
        user: 0
      }
    },
    Update() {
      // if (
      //   !this.formData.amount ||
      //   !this.formData.investment_time ||
      //   !this.formData.service_charge ||
      //   !this.formData.user
      // ) {
      //   this.$toast.open({
      //     message: 'Chưa nhập đầy đủ thông tin !',
      //     type: 'error',
      //     position: 'top',
      //     duration: 3000,
      //   });
      //   return false;
      // }
      network.post(`/products/update`, {
        idProduct: this.idProduct,
        formData: this.formData
      }).then(res => {
        this.$toast.open({
          message: 'Cập nhật thành công',
          type: 'success',
          position: 'top',
          duration: 3000,
        });
        this.initialize();
        this.resetForm();
      }).catch(err => {
        this.$toast.open({
          message: err.response.data.message,
          type: 'error',
          position: 'top',
          duration: 3000,
        });
      })
    },
    getUser(id) {
      network.get(`/users/${id}`).then(res => {
        const item = res.data.data.profile;
        this.user = { text: item.name, value: item._id }
        this.formData.user = item._id;
      });
    },
    submitForm() {
      // if (
      //   !this.formData.amount ||
      //   !this.formData.investment_time ||
      //   !this.formData.service_charge ||
      //   !this.formData.user
      // ) {
      //   this.$toast.open({
      //     message: 'Chưa nhập đầy đủ thông tin !',
      //     type: 'error',
      //     position: 'top',
      //     duration: 3000,
      //   });
      //   return false;
      // }

      network.post('/products/create', this.formData).then(res => {
        this.$toast.open({
          message: 'Tạo thành công',
          type: 'success',
          position: 'top',
          duration: 3000,
        });
        this.initialize();
        this.payload = false;
        this.resetForm();
      }).catch(err => {
        console.log(err);
      });
    },

    async readFileAsBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async () => {
          const image = new Image();
          image.src = reader.result;

          image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Define the new dimensions for the resized image
            const maxWidth = 200; // Adjust as needed
            const maxHeight = 200; // Adjust as needed
            let width = image.width;
            let height = image.height;

            if (width > height) {
              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            }

            canvas.width = width;
            canvas.height = height;

            // Draw the image onto the canvas
            ctx.drawImage(image, 0, 0, width, height);

            // Convert the canvas content to a data URL
            const resizedDataURL = canvas.toDataURL('image/jpeg');

            // Resolve the promise with the resized data URL
            resolve(resizedDataURL);
          };
        };

        reader.onerror = error => {
          reject(error);
        };
      });
    },

    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.file = file;
        this.imageUrl = URL.createObjectURL(file);
        const uriImg = this.convertBase64ToBinary(await this.readFileAsBase64(file));

        const formData = new FormData();
        formData.append('file', uriImg);

        await axios.post(
          network.URI('/upload'),
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                'access_token'
              )}`,
            }
          }
        ).then(res => {
          console.log(res.data.secure_url);
          this.formData.image = res.data.secure_url;
        }).catch(err => {
          console.log(err);
        })
      }
    },

    openFileInput() {
      this.$refs.fileInput.click();
    },

    convertBase64ToBinary(dataURI) {
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
      else byteString = unescape(dataURI.split(',')[1]);

      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },
    deleteUser(id) {
      network.post('/products/delete',
        { id: id }
      ).then(res => {
        console.log(res);
        if (res.status == 200) {
          this.$toast.open({
            message: res.data.message,
            type: 'success',
            position: 'top'
          });
          this.initialize();
        } else {
          this.$toast.open({
            message: res.data.message,
            type: 'error',
            position: 'top'
          });
        }
      }).catch(err => {
        this.$toast.open({
          message: err,
          type: 'error',
          position: 'top'
        });
      });
    }
  }

}
</script>

<style scroped>
#uploadButton {
  padding: 10px 20px;
  background-color: #007bff;
  border-radius: 5px;
  cursor: pointer;
}

#uploadButton:hover {
  background-color: #0056b3;
}
</style>